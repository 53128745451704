import { useState } from "react";
import { livros } from "./dadosPesquisa"

export default function PesquisarVeiculoAplicacao() {

    const [livrosPesquisados, setLivrosPesquisados] = useState([])

    return (
        <section>

            <h2>Encontre o Veiculo</h2>
            <h3>teste</h3>
            <input placeholder="Digite a montadora do veiculo"
                onBlur={evento => {
                    const textoDigitado = evento.target.value;
                    const resultadoPesquisa = livros.filter(livro => livro.nome.includes(textoDigitado));
                    setLivrosPesquisados(resultadoPesquisa);

                }} />

            {
                livrosPesquisados.map(livro => (

                    <div>
                        <p>{livro.nome} </p>
                        <img src={'../../imagens/' + livro.nome +'.src'} alt={livro.nome}></img>
                    </div>
                ))
            }

        </section>
    );
}