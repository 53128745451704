import perfil from 'imagens/perfil.svg'
import sacola from 'imagens/sacola.svg'
import carro from 'imagens/car-repair.svg'

export const links = [{
    nome: 'Carro',
    src: carro,
    href: '/ordem/servico'
},
{
    nome: 'Login',
    src: perfil,
    href: '/login'
}
]