import { Outlet } from 'react-router-dom';
import Header from 'componentes/Header';
import MenuLateral from 'componentes/MenuLateral';
import { AppContainer, Container, ContainerPrincipal } from 'componentes/Containers';

function PaginaBasePrivada() {
    return (
        <Container>

            <AppContainer>
                <Header/>
                <MenuLateral/>
                <ContainerPrincipal>
                    <Outlet />
                </ContainerPrincipal>
            </AppContainer>
        </Container>
    );
}

export default PaginaBasePrivada;