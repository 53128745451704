import { useParams } from "react-router-dom"

export default function DetalhesVeiculoAplicacao (){
    const parametros = useParams();
    
    if(!parametros){
        return (
            <h1>Não encontrado</h1>
        )
    }
    
    return (
        <h1>
            {parametros.id}
        </h1>
    )
}