import IconesHeader from 'componentes/IconesHeader';
import Logo from 'componentes/Logo';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  background-color: #012267;
  width: 100vw;
  height: 10%;
  display: flex;
  position: fixed;
`

export default function Header() {
    return (
    <HeaderContainer>
        <Logo></Logo>
        <IconesHeader></IconesHeader>
      </HeaderContainer>

    );
}