import styled from 'styled-components';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ContainerPrincipal = styled.div`
    display: flex;
    padding-left: 80px;
    margin-top: calc(100vh / 10);
    a {
      text-decoration: none;
    }
`

export const AppContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  background-image: linear-gradient(0deg, #012267 15%, #9aaafa);
`