import apiVendas from "Integracoes/ApiVendas";
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import styled from "styled-components";

const ProdutoImg = styled.img`
    max-height: 150px;
    max-width: 150px;
    margin-left: 45px;
    border-radius: 20px;
    background-color: #ffffff;
`

const PainelConteudo = styled.div`
    width: calc(100vw - 90px);
    height: calc(100vh - 350px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 50px;
`

const ProdutoPesquisaCampo = styled.input`
    width: 400px;
    @media (max-width: 768px) {
        width: 180px;
    }
`

const DescricaProduto = styled.div`
    color: #f79c17;
    text-decoration: none;
    font-size: 16px;
    margin-left: 5px;
    padding-bottom: 10px;
    margin-right: 5px;
`
const ProdutoColumn = styled.div`
    float: left;
    width: 260px;
    padding: 0 10px;
    margin-bottom: 20px;
    @media screen and (max-width: 600px) {
          display: block;
    }
`
const ProdutoCard = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 340px;
    width: 250px;
    padding: 10px 0 40px 1%;
    border: 2px solid #f79c17;
    border-radius: 20px;
    background-color: #012267;
`

const ProdutoRow = styled.div`
    margin: 0 -5px;
    :after {
        content: "";
        display: table;
        clear: both;
  }
`

export default function PesquisarProduto(){
    
    function pesquisaProduto(){
        var inputFiltro = document.getElementById('txtFiltro')
        setFiltroProduto(inputFiltro.value);
    }

    const [filtroProduto, setFiltroProduto] = useState();
    const [produtos, setProdutos] = useState([]);

    useEffect(() => {
        apiVendas.get('produto/pesquisa/generica?filtroGenerico='+filtroProduto)
        .then(response => {
            setProdutos(response.data)
        }

        )
        .catch(erro => {
            console.log(erro)
        })

    }, [filtroProduto])

    function currencyFormat(num) {
        return num ? "R$" + num.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) :
            "R$ - ";
    }

    return (
        <div>

        <h1>Pesquisar produto por nome, codigo ou codigo de barras</h1>
        <ProdutoPesquisaCampo id="txtFiltro" onKeyDownCapture={event => {
                if (event.key === 'Tab' || event.key === 'Enter' ) {
                  pesquisaProduto()
                }
              }}></ProdutoPesquisaCampo>
        <button className="bi bi-search" onClick={() => pesquisaProduto()}/>
        <PainelConteudo >

        <ProdutoRow>
        {produtos.map((produto) => (
        
            <ProdutoColumn>

                <ProdutoCard>

                    <Link to={"/produto/cadastro/"+produto.idProduto}>
                        <ProdutoImg  src={produto.urlImagem} alt={produto.coProduto} />
                        <DescricaProduto><strong>{produto.nome}</strong></DescricaProduto>
                        <DescricaProduto><strong>Marca: {produto.marcaProduto.nome}</strong></DescricaProduto>
                        <DescricaProduto><strong>Embalagem: {produto.unidade}</strong></DescricaProduto>
                        <DescricaProduto><strong>Estoque: {produto.qtdEstoque}</strong></DescricaProduto>
                        <DescricaProduto><strong>Valor à vista: {currencyFormat(produto.valorVista ? produto.valorVista : produto.valorMinimoVenda )}</strong></DescricaProduto>
                    </Link>
                </ProdutoCard>
            </ProdutoColumn>

        ))}

        </ProdutoRow>
        </PainelConteudo>
        </div>
    )
}