import { BrowserRouter, Route, Routes } from "react-router-dom";


import Login from "./pages/Login";
import PaginaPrincipal from "./pages/PaginaPrincipal";
import PaginaBasePublica from "./pages/PaginaBasePublica";
import PaginaBasePrivada from "./pages/PaginaBasePrivada";
import OrdemServico from "./pages/Ordem/OrdemServico";
import OrdemCompra from "./pages/Ordem/OrdemCompra";
import CadastroCliente from "./pages/Cliente/CadastroCliente";
import PesquisarCliente from "./pages/Cliente/PesquisarCliente";
import CadastroFornecedor from "./pages/Fornecedor/CadastroFornecedor";
import PesquisarFornecedor from "./pages/Fornecedor/PesquisarFornecedor";
import PesquisarProduto from "./pages/Produto/PesquisarProduto";
import CadastroProduto from "./pages/Produto/CadastroProduto";
import CadastroOrdemServico from "./pages/Ordem/OrdemServico/CadastroOrdemServico";
import PesquisarOrdemServico from "./pages/Ordem/OrdemServico/PesquisarOrdemServico";
import CadastroOrdemCompra from "./pages/Ordem/OrdemCompra/CadastroOrdemCompra";
import PesquisarOrdemCompra from "./pages/Ordem/OrdemCompra/PesquisarOrdemCompra";
import PesquisarVeiculoAplicacao from "./pages/VeiculoAplicacao/PesquisarVeiculoAplicacao";
import SolicitacaoCompra from "./pages/Produto/SolicitacaoCompra";
import Notificacao from "./pages/Cliente/Notificacao";
import DetalhesVeiculoAplicacao from "./pages/VeiculoAplicacao/DetalhesVeiculoAplicacao";
import Financeiro from "./pages/Financeiro";
import PesquisarMeuVeiculo from "./pages/Cliente/PesquisarMeuVeiculo";
import DetalhesMeuVeiculo from "./pages/Cliente/DestalhesMeuVeiculo";
import PesquisarOleoAplicacao from "./pages/OleoAplicacao/PesquisarOleoAplicacao";
import DetalhesOleoAplicacao from "./pages/OleoAplicacao/DetalhesOleoAplicacao";


function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<PaginaPrincipal />} />
                <Route path="/" element={<PaginaBasePublica />} >
                    <Route path="/login" element={<Login />} />
                </Route>
                <Route path="/"  element={<PaginaBasePrivada />} >
                    <Route path="/ordem/servico" element={<OrdemServico />} />
                    <Route path="/ordem/servico/cadastro/:id" element={<CadastroOrdemServico />} />
                    <Route path="/ordem/servico/pesquisa" element={<PesquisarOrdemServico />} />

                    <Route path="/ordem/compra" element={<OrdemCompra />} />
                    <Route path="/ordem/compra/cadastro/:id" element={<CadastroOrdemCompra />} />
                    <Route path="/ordem/compra/pesquisa" element={<PesquisarOrdemCompra />} />

                    <Route path="/cliente/cadastro/:id" element={<CadastroCliente />} />
                    <Route path="/cliente/pesquisa" element={<PesquisarCliente />} />
                    <Route path="/cliente/notificacao" element={<Notificacao />} />

                    <Route path="/fornecedor/cadastro/:id" element={<CadastroFornecedor />} />
                    <Route path="/fornecedor/pesquisa" element={<PesquisarFornecedor />} />

                    <Route path="/produto/cadastro/:id" element={<CadastroProduto />} />
                    <Route path="/produto/pesquisa" element={<PesquisarProduto />} />
                    <Route path="/produto/solicitacao/compra" element={<SolicitacaoCompra />} />

                    <Route path="/veiculo/aplicacao/pesquisa" element={<PesquisarVeiculoAplicacao />} />
                    <Route path="/veiculo/aplicacao/detalhes/:id" element={<DetalhesVeiculoAplicacao />} />

                    <Route path="/oleo/aplicacao/pesquisa" element={<PesquisarOleoAplicacao/>}/>
                    <Route path="/oleo/aplicacao/detalhes/:id" element={<DetalhesOleoAplicacao/>}/>

                    <Route path="/financeiro" element={<Financeiro/>} />

                </Route>

                <Route path="/" element={<PaginaBasePublica />} >
                    <Route path="/meuveiculo/situacao/pesquisa" element={<PesquisarMeuVeiculo/>}/> 
                    <Route path="/meuveiculo/situacao/detalhes" element={<DetalhesMeuVeiculo/>}/>
                </Route>

                <Route path="*" element={<PaginaPrincipal />} />

            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;
