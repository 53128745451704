// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
    order: 1px solid #FFF;
    background: transparente;
    border: 1px solid #FFF;
    padding: 15px 20px;
    border-radius: 50px;
    color: #012267;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;
}

&::placeholder{
    color: #FFF;
    font-size: 16px;
}

button {
    padding: 15px 20px;
    border-radius: 50px; 
    font-size: 16px;
    background: #012267;
    color: #f79c17;
}

button:hover{
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/estilo.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,wBAAwB;IACxB,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["input {\r\n    order: 1px solid #FFF;\r\n    background: transparente;\r\n    border: 1px solid #FFF;\r\n    padding: 15px 20px;\r\n    border-radius: 50px;\r\n    color: #012267;\r\n    font-size: 16px;\r\n    margin-bottom: 10px;\r\n    text-align: left;\r\n}\r\n\r\n&::placeholder{\r\n    color: #FFF;\r\n    font-size: 16px;\r\n}\r\n\r\nbutton {\r\n    padding: 15px 20px;\r\n    border-radius: 50px; \r\n    font-size: 16px;\r\n    background: #012267;\r\n    color: #f79c17;\r\n}\r\n\r\nbutton:hover{\r\n    cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
