export const links = [{
    nome: 'Inicio',
    src: "bi bi-house-door",
    href: '/'
},
//{
//    nome: 'Compras',
//    src: "bi bi-handbag",
//    href: '/ordem/compra/pesquisa'
//},
{
    nome: 'Serviços',
    src: "bi bi-wrench-adjustable",
    href: '/ordem/servico/pesquisa'
},
{
    nome: 'Produtos',
    src: "bi bi-upc-scan",
    href: '/produto/pesquisa'
},
{
    nome: 'Cliente',
    src: "bi bi-person",
    href: '/cliente/pesquisa'
},
{
    nome: 'Fornecedor',
    src: "bi bi-buildings",
    href: '/fornecedor/pesquisa'
},
{
    nome: 'Óleos',
    src: "bi bi-droplet-half",
    href: '/oleo/aplicacao/pesquisa'
},
{
    nome: 'Notificações',
    src: "bi bi-chat-text",
    href: '/cliente/notificacao'
},
{
    nome: 'Financeiro',
    src: "bi bi-cash-coin",
    href: '/financeiro'
},
{
    nome: 'Veículos Aplicação',
    src: "bi bi-car-front",
    href: '/veiculo/aplicacao/pesquisa'
},
{
    nome: 'Conversão Filtros',
    src: "bi bi-arrow-left-right",
    href: '/filtro/conversao'
},
{
    nome: 'Meu veículo',
    src: "bi bi-ev-front",
    href: '/meuveiculo/situacao/pesquisa'
}

]