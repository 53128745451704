import apiAplicacoes from "Integracoes/ApiAplicacoes";
import apiVendas from "Integracoes/ApiVendas";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components";

const ProdutoImg = styled.img`
    max-height: 300px;
    max-width: 510px;
    border-radius: 20px;
    background-color: #ffffff;
    @media (max-width: 768px) {
        max-height: 200px;
        max-width: 210px;
    }
`

const PainelConteudo = styled.div`
    width: calc(100vw - 90px);
    height: calc(100vh - 250px);
    overflow-x: hidden;
    overflow-y: auto;

    table {
        width: calc(100vw - 160px);
        
        color: #f79c17;
        font-size: 18px;
        border: 1px solid #f79c17;
    }

    table {
        background-color: #012267;
        border-radius: 20px;
        text-align: left;
    }
    
    th, td {
        padding-left: 20px;
        padding-right: 20px;
    }

    tr.linha:hover {
        background-color: #6c62f7;
        border-radius: 20px;
    }
`

const ProdutoRow = styled.div`
    margin: 0 -5px;
    :after {
        content: "";
        display: table;
        clear: both;
  }
`

const ProdutoColumn = styled.div`
    float: left;
    padding: 0 10px;
    margin-bottom: 20px;
`

const DescricaProduto = styled.div`
    font-weight: bold;
    padding: 5px 10px;
    font-size: 20px;
`

export default function CadastroProduto() {
    const parametros = useParams();

    const [veiculos, setVeiculos] = useState([]);
    const [produto, setProduto] = useState([]);
    const [idProduto, setIdProduto] = useState([]);
    const [filtroDetalhes, setFiltroDetalhes] = useState();
    const navegar = useNavigate();

    useState(() => {
        setIdProduto(parametros.id)
    })

    useEffect(() => {
        apiVendas.get('produto/pesquisa/id?idProduto=' + idProduto)
            .then(response => {
                setProduto(response.data)
            }

            )
            .catch(erro => {
                console.log(erro)
            })

    }, [idProduto])

    useEffect(() => {
        if (produto && produto.tipoProduto && produto.tipoProduto.nome === 'Filtro') {
            apiAplicacoes.get('filtro/aplicacao/pesquisar?codFiltroFabricante=' + produto.marcaProduto.nome + produto.coProduto)
                .then(response => {
                    setVeiculos(response.data)
                }

                )
                .catch(erro => {
                    console.log(erro)
                });
                apiAplicacoes.get('filtro/detalhes/pesquisar?coFiltro=' + produto.marcaProduto.nome + produto.coProduto)
                .then(response => {
                    setFiltroDetalhes(response.data)
                }

                )
                .catch(erro => {
                    console.log(erro)
                })

        }
    }, [produto])

    function FiltroDetalhes(){
        if(filtroDetalhes){
            console.log(filtroDetalhes)
            return (
                <ProdutoColumn>
                    <DescricaProduto>Conversão Original: {filtroDetalhes.coOriginal}  </DescricaProduto>
                    <DescricaProduto>Elemento Filtrante: {filtroDetalhes.elementoFiltrante}  </DescricaProduto>
                    <DescricaProduto>Altura: {filtroDetalhes.altura}  </DescricaProduto>
                    <DescricaProduto>Diametro comprimento: {filtroDetalhes.diametroExtComp}  </DescricaProduto>
                    <DescricaProduto>Diametro largura: {filtroDetalhes.diametroIntLarg}  </DescricaProduto>
                    <DescricaProduto>Peso bruto: {filtroDetalhes.pesoBruto}  </DescricaProduto>
                    <DescricaProduto>Peso liquido: {filtroDetalhes.pesoLiquido}  </DescricaProduto>
                    <DescricaProduto>Rosca: {filtroDetalhes.rosca}  </DescricaProduto>
                    <DescricaProduto>Valvula de alivio: {filtroDetalhes.valvulaAlivio  ? 'Sim' : 'Não'}  </DescricaProduto>
                    <DescricaProduto>Valvula anti-retorno: {filtroDetalhes.valvulaAntiRetorno ? 'Sim' : 'Não'}  </DescricaProduto>
                    <DescricaProduto>Fora de Linha: {filtroDetalhes.foraDeLinha  ? 'Sim' : 'Não'}  </DescricaProduto>
                    <DescricaProduto>Observação: {filtroDetalhes.observacao}  </DescricaProduto>
                </ProdutoColumn>
            )
        }
    }

    function TabelaVeiculos(){
        if(veiculos.length > 0 ){
            return (
                <table>
                    <tr>
                        <th>Linha</th>
                        <th>Montadora</th>
                        <th>Modelo</th>
                        <th>Motor</th>
                        <th>Descrição</th>
                        <th>Combustível</th>
                        <th>Ano de</th>
                        <th>Ano até</th>
                    </tr>
                    {veiculos.map((veiculo) => (
                        <tr className="linha" >
                                <td>{veiculo.modeloVeiculo.linha}</td>
                                <td>{veiculo.modeloVeiculo.montadora.nome}</td>
                                <td>{veiculo.modeloVeiculo.nome}</td>
                                <td>{veiculo.motor}</td>
                                <td>{veiculo.descricao}</td>
                                <td>{veiculo.combustivel}</td>
                                <td>{veiculo.anoInicio}</td>
                                <td>{veiculo.anoFim}</td>
                                <Link to={"/veiculo/aplicacao/detalhes/"+veiculo.idVeiculo}>
                                </Link>
                            </tr>
                    ))}
                </table>
            )
        }  
        return ""
    }

    function currencyFormat(num) {
        return num ? "R$" + num.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) :
            "R$ - ";
    }

    return (
        <div>
            <h1>Cadastro de Produtos

            </h1>
              

            <PainelConteudo>
            <ProdutoRow>
                <ProdutoColumn>
                    <ProdutoImg src={produto ? produto.urlImagem : ''} alt={produto.coProduto} />
                    <ProdutoRow>
                        <button className="bi bi-reply" onClick={() => navegar(-1)}>  voltar</button>

                        <button className="bi bi-box-seam" onClick={() => navegar(-1)}>  comprar</button>

                        <button className="bi bi-box-seam" onClick={() => navegar(-1)}>  estoque</button>
                    </ProdutoRow>
                </ProdutoColumn>
                <ProdutoColumn>
                    <DescricaProduto>Marca: {produto.marcaProduto ? produto.marcaProduto.nome : ''} </DescricaProduto>
                    <DescricaProduto>Nome: {produto.nome}  </DescricaProduto>
                    <DescricaProduto>Código: {produto.coProduto}  </DescricaProduto>
                    <DescricaProduto>Código Barras GTIN: {produto.coBarrasGtin}  </DescricaProduto>
                    <DescricaProduto>Tipo: {produto.tipoProduto ? produto.tipoProduto.nome : ''}  </DescricaProduto>
                    <DescricaProduto>Embalagem: {produto.unidade}  </DescricaProduto>
                    <DescricaProduto>Perecível: {produto.tipoProduto && produto.tipoProduto.perecivel ? 'Sim' : 'Não'}  </DescricaProduto>
                    <DescricaProduto>Reposição estoque: {produto.reposicao ? 'Sim' : 'Não'}  </DescricaProduto>
                    <DescricaProduto>Estoque: {produto.qtdEstoque}  </DescricaProduto>
                    <DescricaProduto>Valor à vista: {currencyFormat(produto.valorVista ? produto.valorVista : produto.valorMinimoVenda)}</DescricaProduto>
                    <DescricaProduto>Valor sugerido: {currencyFormat(produto.valorMinimoVenda)}</DescricaProduto>
                </ProdutoColumn>
                {FiltroDetalhes()}
            </ProdutoRow>


            {TabelaVeiculos()}
            </PainelConteudo>

        </div>
    );

}