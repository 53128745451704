import Header from 'componentes/Header';
import { AppContainer, Container, ContainerPrincipal } from 'componentes/Containers';
import MenuLateral from 'componentes/MenuLateral';

function PaginaPrincipal() {
    return (
        <Container>
            <AppContainer>
                <Header/>
                
                <MenuLateral />
                <ContainerPrincipal>
                    teste Pagina principal...

                </ContainerPrincipal>
                
            </AppContainer>
        </Container>
    );
}

export default PaginaPrincipal;