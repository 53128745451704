import axios from "axios";


axios.defaults.headers = {
    'Content-Type': 'application/json;charset=utf-8'
}

const apiAplicacoes = axios.create({
    baseURL: "https://veiculos-aplicacoes-m7eb44nbbq-rj.a.run.app/",
});


export default apiAplicacoes;