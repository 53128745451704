import axios from "axios";


axios.defaults.headers = {
    'Content-Type': 'application/json;charset=utf-8'
}

const apiVendas = axios.create({
    baseURL: "https://erp-vendas-back-m7eb44nbbq-rj.a.run.app/",
});


export default apiVendas;