import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { links } from './dadosIconesHeader';


const Icones = styled.div`
  margin-top: 10px;
  margin-left: 30px;
  display: flex;
`

const Botao = styled.img`
    margin-right: 5px;
    margin-top: 5px;
    margin-left: 5px;
    height: 40%;
`

function IconesHeader() {
  return (
    <Icones>
      {links.map((link) => (
        <Link nome={link.nome} to={link.href}>
          <Botao src={link.src} alt={link.nome}></Botao>
        </Link>
      ))}
    </Icones>
  );
}

export default IconesHeader;