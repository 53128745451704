import './estilo.css'
import { links } from './dadosLinksMenuLateral'
import { Link, useLocation } from 'react-router-dom'

function expandir(){
    var menuSide = document.querySelector('.menu-lateral')
    menuSide.classList.toggle('expandir')
}

function expandirOff(){
    var menuSide = document.querySelector('.menu-lateral')
    menuSide.classList.remove('expandir')
}


export default function MenuLateral() {
    const localizacao = useLocation();
    return (
        <nav className='menu-lateral'>
            <div className='btn-expandir'>
                <i class="bi bi-list" onClick={expandir}></i>
            </div>

            <ul>
            {links.map((link) => (
                <li key={'icon'+link.nome} className={`
                    ${link.href === localizacao.pathname ? 'item-menu linkAtivo' : 'item-menu'}
                `} >
                <Link nome={link.nome} to={link.href} onClick={expandirOff}>
                        <span className='icon'><i class={link.src}></i></span>
                        <span className='txt-link'>{link.nome}</span>
                </Link>
                </li>
            ))}
            </ul>
        </nav>
    )
}