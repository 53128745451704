import logo from 'imagens/nelson-logo.png'
import styled from 'styled-components'

const LogoContainer = styled.div`
    display: flex;
    color: white;
`
const LogoImg = styled.img`
    margin-right: -30px;
    margin-top: 15px;
    margin-left: 15px;
    height: 80%;

    @media (max-width: 768px) {
        height: 50%;
    }
`

export default function Logo() {
    return (
        <LogoContainer>
            <LogoImg src={logo} alt='logo'></LogoImg>
        </LogoContainer>

    );
}